import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// // import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "How do I qualify for an FHA loan?",
      answer: (
        <>
          <p>
            Here are the main criteria you’ll need to meet to qualify for an FHA
            loan:
          </p>
          <ul className="styled-list-checkmark">
            <li>A credit score of 500 or higher</li>
            <li>
              A debt-to-income ratio of 43% or less; however, debt-to-income
              ratios as high as 55% are possible
            </li>
            <li>A minimum down payment of 3.5%</li>
            <li>You must have a steady income and proof of employment</li>
          </ul>
        </>
      ),
    },
    {
      question: "What are the FHA loan limits in Orange County?",
      answer: (
        <>
          <p>
            Every year, the FHA sets loan limits for FHA-backed loans and they
            base them on the average home price for the area. The limit is 115%
            of the median home price, or in some cases 150% if it's in a
            high-cost area.
          </p>
          <p>
            TThe limits fluctuate and you can check them at any time by visiting
            the U.S. Department of Housing and Urban Development’s{" "}
            <a
              href="https://entp.hud.gov/idapp/html/hicostlook.cfm"
              target="_blank"
            >
              FHA Mortgage Limits lookup page
            </a>
            .
          </p>
        </>
      ),
    },
    {
      question:
        "Can I qualify for an FHA loan if I have a bankruptcy on my record?",
      answer: (
        <>
          <p>
            You can, although there is a waiting period that FHA guidelines
            require:
          </p>
          <ul className="styled-list-checkmark">
            <li>Chapter 13: You can apply for an FHA loan after one yearr</li>
            <li>Chapter 7: You can apply for an FHA loan after two years</li>
          </ul>
        </>
      ),
    },
    {
      question: "How do I qualify for a VA loan?",
      answer: (
        <>
          <p>
            Here are the main criteria you’ll need to meet to qualify for a VA
            loan:
          </p>
          <div className="heading-six">Service Requirements</div>
          <p>You must meet at least one of the following criteria:</p>
          <ul className="styled-list-checkmark mb-6">
            <li>Served 181 days of active service during peacetime, or</li>
            <li>
              Served 90 consecutive days of active service during wartime, or
            </li>
            <li>
              Served more than six years with the National Guard or Reserves, or
            </li>
            <li>
              Are the spouse of a service member who died in the line of duty or
              as the result of a disability connected to their military service
            </li>
          </ul>

          <div className="heading-six">Documents You’ll Need to Provide</div>
          <ul className="styled-list-checkmark mb-6">
            <li>
              For all borrowers: A Certificate of Eligibility (COE) that shows
              you’re eligible for a VA loan
            </li>
            <li>
              If you’re active military: Statement of Service letter that
              verifies your employment with the military
            </li>
            <li>
              If you’re a veteran: DD Form 214, which is your Certificate of
              Release or Discharge from Active Duty
            </li>
            <li>
              If you’re a surviving spouse who receives dependency benefits: VA
              form 26-1817
            </li>
            <li>
              If you’re a surviving spouse who does not receive dependency
              benefits: VA form 21P-534
            </li>
          </ul>

          <div className="heading-six">Other Requirements</div>
          <ul className="styled-list-checkmark">
            <li>The property you buy must be your primary residence</li>
            <li>
              Credit and debt-to-income requirements are more lenient and will
              vary by lender{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "What is a VA funding fee?",
      answer: (
        <>
          <p>
            Every VA loan requires that you pay a one-time payment to the
            Department of Veterans Affairs, which supports the VA home loan
            program.
          </p>
          <p>
            The VA funding fee ranges between 0.5 and 3.6 percent of your loan
            amount and can be rolled into your mortgage.
          </p>
          <p>
            There is an exemption for eligible borrowers who have a VA-approved
            disability related to their military service, have received a Purple
            Heart, or are a surviving spouse.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="FHA & VA Home Loans Orange County | Nikkael"
        description="At Nikkael Home Loans, we're experts in FHA and VA loans in Orange County, and we’re armed with answers to all your questions. Contact us today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Orange County FHA & VA Loans</h1>
              <p>
                FHA and VA loans are the most popular government-insured loans.
                FHA loans come with lower down payments, affordable interest
                rates, and easier credit score requirements. If you’re a current
                or former military member or a surviving spouse, you may be able
                to buy a home with no money down using a VA loan.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/FHA + VA/1.0 FHA _ VA Loans.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/FHA + VA/FHA Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>FHA Loans</h2>
              <p>
                FHA home loans are backed by the government and insured by the
                Federal Housing Administration. They can be used to purchase a
                home or refinance an existing mortgage. Also, if you’re eyeing a
                property that isn’t exactly perfect, you can add renovation
                costs to an FHA loan. Here are more reasons why FHA loans are
                attractive to prospective homebuyers:
              </p>
              <ul className="styled-list-checkmark mb-6">
                <li>Easier credit and debt-to-income qualification</li>
                <li>Down payment as low as 3.5 percent</li>
                <li>Affordable interest rates</li>
                <li>No income limits</li>
                <li>Gift funds accepted for the down payment</li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>VA Loans</h2>
              <p>
                VA home loans are available to qualifying active military
                service members and veterans and their surviving spouses.
                Private lenders issue VA loans backed by the U.S. Department of
                Veterans Affairs, which sets the qualifying standards and terms.
                VA loans come with great benefits that include:
              </p>
              <ul className="styled-list-checkmark mb-6">
                <li>No down payment</li>
                <li>No mortgage insurance</li>
                <li>Competitive interest rates</li>
                <li>Relaxed credit requirements</li>
                <li>No prepayment penalties</li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/FHA + VA/FHA Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      {/* {/* <HomeWorth /> */}
      <FAQs heading="Common Questions About FHA & VA Loans" uniqueFaqs={faqs} />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[4, 5]}
      />
      <CallToAction
        heading="Considering an FHA or VA Home Loan?"
        text="We’re experts in FHA and VA loans in Orange County, and we’re armed with answers to all your questions. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-fha-va.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-fha-va.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
